<template>
  <div class="formview">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="text-center">
            <p class="w-back mb-0">Welcome Back!</p>
            <h3 class="formheader f-semibold text-dark">
              Login to Your Account
            </h3>
          </div>
          <form @submit.prevent="submitForm">
            <div class="my-5">
              <div class="form-group mb-3">
                <input
                  v-model="email"
                  required
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  class="form-control text-dark"
                />
              </div>
              <div class="form-group mb-3">
                <input
                  required
                  v-model="password"
                  placeholder="Enter your password"
                  type="password"
                  class="form-control text-dark"
                />
                <div class="text-right mt-4">
                  <router-link class="text-primary" to="/forgot-password"
                    >Forgot password?</router-link
                  >
                </div>
              </div>
              <div class="form-group text-center mt-5">
                <button
                  :disabled="loading"
                  class="
                    btn btn-imp-secondary
                    bg-primary
                    btn-icon
                    text-secondary
                    py-3
                    px-5
                    btn-block
                  "
                  type="submit"
                >
                  <span>Login</span>
                  <BtnLoading v-if="loading" class="btn-loading" />
                </button>
              </div>
              <div class="text-center mt-4">
                Dont have an account?
                <span class="su text-primary" @click="handleRegister">
                  Sign Up</span
                >

                <!-- <router-link class="text-primary" to="/sign-up"
                  >Sign Up</router-link
                > -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  components: { BtnLoading },
  computed: {
    cartRedirect() {
      return this.$route.query.cart;
    },
    sessionTimeout() {
      return this.$route.query.r;
    },
  },

  mounted() {
    if (this.cartRedirect) {
      this.$toast.info(
        "Login",
        "You have to login or signup before you can checkout",
        this.$toastPosition
      );
    }
    if (this.sessionTimeout === "timeout") {
      this.$toast.info(
        "Login",
        "You have been logged out due to inactivity ",
        this.$toastPosition
      );
      this.$router.push("/login");
    }
    if (this.sessionTimeout === "auth") {
      this.$toast.info(
        "Login",
        "Invalid token, Please login again ",
        this.$toastPosition
      );
      this.$router.push("/login");
    }
  },
  methods: {
    submitForm() {
      this.loading = true;
      let data = {
        email: this.email,
        password: this.password,
      };

      data = this.$helpers.encrypt(JSON.stringify(data));

      data = {
        data,
      };

      // console.log(data);

      let payload = {
        data,
        path: "/user/login",
      };
      // console.log(payload);
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          // console.log(resp);
          // console.log(decr);
          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;
          // console.log(resp);
          this.loading = false;
          let token = resp.data.data.token;
          let user = {
            user: resp.data.data.user,
            customer: resp.data.data.customer,
          };

          token = this.CryptoJS.AES.encrypt(token, this.$passPhrase).toString();

          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("token", token);
          localStorage.setItem("user", user);

          this.$toast.success(
            "Login",
            "Login successful.",
            this.$toastPosition
          );

          setTimeout(() => {
            if (this.cartRedirect) {
              window.location.href = "/cart";
            } else {
              window.location.href = "/";
            }
          }, 1000);
          // return;
        })
        .catch((err) => {
          if (err.response) {
            // console.log(err.response);
            // console.log(decr);
            let decr = JSON.parse(
              this.$helpers.decrypt(err.response.data.data)
            );
            err.response.data = decr;
            this.$toast.info(
              "Login",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Login",
              "Unable to login, check your internet connection",
              this.$toastPosition
            );
          }
          this.loading = false;
        });
    },
    handleRegister() {
      if (this.$route.query.cart === "true") {
        localStorage.setItem(
          "from-route",
          this.$router.push("/sign-up?cart=true")
        );
      } else {
        this.$router.push("/sign-up");
      }
    },
  },
};
</script>
<style  scoped>
.w-back {
  font-weight: bold;
  font-size: 16px;
  line-height: 52px;
  color: #255e13;
}
.formheader f-bold {
  font-size: 24px;
  color: #121113;
}
.formview {
  padding: 55px 0px;
  min-height: 90vh;
}
.su {
  cursor: pointer;
}
</style>
